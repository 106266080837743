// tslint:disable:variable-name
type IncludedTranslations = 'en' | 'fi_FI';
const en = {
  welcome: 'Finnair privacy commitment',
  changeLanguage: 'Change language',
  ingress:
    "Data protection is a key priority for Finnair. We are committed to respecting our customers' privacy and protecting their personal data.",
  home: 'Home',
  login: {
    loggedIn: "I can see you're logged in as {0}. Welcome {1}!",
    callToAction: 'Login',
    logoutStatus: 'Logout',
    logoutAction: 'Logout',
  },
  languageMenuTitle: 'All languages',
  selectedCountry: {
    finland: 'Finland',
    international: 'International',
  },
  heroImage: {
    heading: 'Placeholder for heading',
    paragraph: 'Place holder for paragraph text',
  },
  landingPage: {
    title:
      'Finnair´s personal data processing principles are applied across all our operations that handle customer data:',
    principle1:
      'We are transparent about the data we collect regarding you as our customer and about the purposes of processing that information.',
    principle2:
      'We respect our customers’ privacy-related rights and provide you with accessible online tools to obtain information about your personal data processed by us.',
    principle3: 'We provide you with choices regarding personal data processing and marketing.',
    principle4:
      'We only collect and use personal data necessary for the predefined purposes described in our privacy policy, such as providing you with requested services and relevant offers as well as enhancing your experience with us.',
    principle5: 'We keep personal data accurate and up to date, retaining it only as long as necessary.',
    principle6:
      'We process your data in accordance with the GDPR and other applicable privacy legislation to protect it from unauthorised access.',
    privacyPolicyLink: 'Our privacy policy',
    privacyPolicyLinkUrl: 'https://www.finnair.com/en/info/finnair-privacy-policy',
    paragraph1:
      ' will give you more detailed information on how and why we process your personal data and who we may share it with.',
    paragraph2:
      'Below, you can edit your cookie and marketing settings, as well as access and request data about your activity and customer history.',
    paragraph3:
      'If you have any questions concerning our privacy policy, you can reach the Finnair Group Data Protection Officer at ',
  },
  navigationMenu: {
    cookieConsents: {
      title: 'Manage your cookie settings',
      description: 'Control how we track your online activity by managing your cookie settings',
    },
    marketingConsents: {
      title: 'Manage your marketing preferences',
      description: 'Choose what kind of marketing messages you want to receive from us',
    },
    exportData: {
      title: 'Make a request concerning your personal data',
      description: 'Access information about your bookings and customer history, request erasure or data portability.',
    },
    download: {
      title: 'Download data',
      description: 'Download your data',
    },
  },
  cookieConsentPage: {
    heroImage: {
      title: 'Cookie preferences',
      ingress: 'See why we use different types of cookies and how they’re used to improve your user experience.\n',
    },
    cookiesTips: 'You can change your preferences anytime via the link at the bottom of the page.',
    beforeCheckboxesText:
      'Below you can select which cookies you allow us to use. You can change your preferences at any time.\n' +
      '\n' +
      'You can find out more about how we collect and process your personal data in the Finnair Privacy Policy.',
    title: 'Manage cookies',
    saveBtn: 'Allow my selection',
    allowAllBtn: 'Allow all',
    changesSaved: 'Changes saved',
    loadingText: 'Loading',
    finnairMayUse: 'Please adjust the slider to confirm your cookie settings',
    intro:
      '<p>Cookies are small data files stored by your browser when you visit a site. We use them too, and while the cookies required for this website to work correctly are always on, you can manage the rest. Please note that rejecting certain cookies may affect your experience of our site and the available features. \n' +
      '<br><a' +
      ' href="https://www.finnair.com/en/info/finnair-cookie-policy" target="_blank" rel="noopener noreferrer">' +
      ' Finnair cookie policy</a>.</p>',
  },
  marketingConsentPage: {
    heroImage: {
      title: 'Marketing preferences',
      ingress:
        'We want to make sure you get the most out of your Finnair Plus membership or Finnair profile and keep you up to date with' +
        ' current offers and events.\n',
    },
    title: 'Manage your marketing preferences',
    beforeCheckboxesText: 'Select what kind of marketing you would like to receive from us and our partners.\n',
    login: 'Are you a Finnair Plus member? Log in to manage your marketing preferences.',
    saveBtn: 'Save',
    confirmSave: 'By saving the changes I confirm the above selections.',
    loadingText: 'Loading',
  },
  exportDataScreen: {
    title: 'Access your personal data',
    ingress:
      'You can use this self-service tool to request access to and erasure of data about your travel history and activity as our customer.',
    fplusIngress: 'Please use this self-service tool to request access to and erasure of your personal data.',
    amIngress:
      'You can use this self-service tool to request access to and erasure of data Sun Tours has about your travel history and activity as our customer.',
    downloadTitle: 'Log in and access your data report',
    downloadIngress: 'Download your finished data report.',
    note: 'Note',
    fplusTitle: 'My trips and activity as a Finnair Plus member',
    fplusField: 'Finnair Plus membership number *',
    fplusHint: 'Did you know that you can see this information right away if you log in to your Finnair Plus account?',
    otherTripsWithFinnair: 'My other trips with Finnair',
    otherTripsWithAurinkomatkat: 'My trips with Aurinkomatkat',
    otherTripsFirstName: 'First name (as in your booking) *',
    otherTripsLastName: 'Family name (as in your booking) *',
    otherTripsBookingReferences: 'Booking references',
    otherTripsPnrsHint: 'Separate multiple booking with a comma, for example Y2KP3D, YHK76W, IUY786',
    otherTripsAmBookingIdsHint: 'Separate multiple booking with a comma, for example 6123456, 6234567, 6345678',
    otherTripsBookingReferencesHint:
      'Did you know that you can see your upcoming bookings directly from Manage booking?',
    otherTripsParagraph:
      "If you don't remember your booking reference, you can also request travel information based on the destination and travel date.",
    otherTripsTravelDetails: 'Travel details (departure city and destination, date)',
    otherTripsTravelDetailsHint: 'For example Helsinki-London, 2018/3/30',
    otherTripsTravelDetailsAdd: 'Add another travel detail row',
    purchasesTitle: 'My purchases in the Finnair Shop, Nordic Sky and during my flight(s)',
    purchasesFplusId: 'Finnair Plus membership number (if you are a member)',
    purchasesName: 'Name *',
    purchasesFlightNumberAndDate: 'Flight number and date',
    purchasesFlightNumberAndDateHint: 'For example AY1723 2018/12/30',
    feedbackTitle: 'Ongoing or past feedback / claim',
    feedbackDetails: 'Case reference number *',
    feedbackDetailsHintFinnair: 'Separate multiple cases with a comma, for example 12345678, 65432178',
    feedbackDetailsHintAurinkomatkat: 'Separate multiple cases with a comma, for example 00012345, 00011111',
    surveyTitle: 'My replies to customer surveys',
    surveyDetails: 'Reply date (year and month), email used to send reply, phone number *',
    surveyDetailsHint: 'For example: 2017/04, test@email.com, +358401234567',
    surveyAddRow: 'Add another survey detail row',
    additionalTitle: 'Additional data I wish to access',
    additionalDetails: 'Details  *',
    additionalDetailsHint:
      'Include all available details, such as the phone number you used to call customer service, the email address' +
      ' you sent message from or the booking reference for a booking discussed via our chat service.',
    additionalParagraphFinnairOne:
      'To access other data not mentioned above, such as Finnair communication data, please contact privacy@finnair.com.',
    additionalParagraphAurinkomatkat:
      'To access other data not mentioned above, such as Aurinkomatkat communication data, please contact privacy@finnair.com.',
    additionalParagraph2: 'I wish to access the data specified above from the following time period:',
    additionalPeriod: 'Time period *',
    additionalPeriodHint: 'For example: 2017/01/01-2018/01/01',
    eraseTitleFinnair: 'I want my personal data to be erased from the Finnair Plus member database',
    eraseTitleAurinkomatkat: 'I want my personal data to be erased from the Aurinkomatkat customer database',
    eraseTitleNotLoggedIn: 'I want my personal data to be erased',
    eraseParagraph:
      'According to your right to be forgotten, you may ask us to delete your personal data from our systems. Please consider that in some cases all of your data cannot be erased, for example if the data are necessary for the performance of a contract or there is an overriding legitimate interest of our company.',
    baseTitle: 'Your contact and identification details',
    baseFirstName: 'First name *',
    baseFamilyName: 'Family name *',
    baseAddress: 'Address *',
    baseEmail: 'Email *',
    basePhoneNumber: 'Phone number *',
    baseOtherContactDetails: 'Other contact details (phone number during office hours, work email address)',
    baseCopyOfPassport: 'Copy of passport',
    submitButton: 'Send request',
    forMoreInfo: 'For more info:',
    privacyPolicy: 'Privacy Policy',
  },
  lightDataRequestForm: {
    title: 'Your data',
    smallHeading: 'I want to view and export the following data:',
    loggedInItems:
      'Your Finnair Plus member details\nYour Finnair Plus transactions\nYour Finnair Plus flights\nFinnair Plus Special Service Requests\nFinnair Plus Consents given',
    itemForBooking: 'Travel data: {0}',
    disclaimer:
      'Your personal data export is generated automatically by our systems. You will typically receive your report the same day the request is made, but please note that in some cases it can take up to 30 days for the report to be generated.\n' +
      '\n' +
      'When your data export is ready to be downloaded, we will send a notification to the email address below:\n',
    email: {
      label: 'Enter your email address *',
    },
    submit: 'Send request',
    processing: 'Processing',
  },
  exportDataSuccessful: {
    title: 'Your request has been submitted',
    ingressLoggedIn: 'You will receive an email with further instructions on how to access your data report.',
    ingressWithoutLoggedIn: 'You will receive an SecureEmail with requested data.',
    callToAction: 'Close and return to start',
  },
  exportDataFailure: {
    title: 'Something went wrong',
    ingress:
      'Unfortunately, there was an error processing your request. The technicians have been notified. Please try again later.',
    callToAction: 'Close and return to start',
  },
  loginToViewData: {
    title: 'Access your personal data through Finnair Plus',
    ingress:
      'You can easily access an automated export of your personal data, including past and future bookings, transactions and more, by logging in to your Finnair Plus account.',
    moveToForm: 'Request data export',
  },
  loginToMarketingConsent: {
    title: 'Manage your marketing preferences',
    ingress: 'Log in to your Finnair Plus or Finnair account to view and edit your marketing preferences.',
    loginTitle: 'Manage your marketing preferences',
    loginDescription: 'Log in to your Finnair Plus or Finnair account to view and edit your marketing preferences.',
    managePreferences: 'Manage preferences',
  },
  exportSingleBookingForm: {
    title: 'View single booking related data',
    ingress: 'If you are not a Finnair Plus member, you can still view data related to a single booking.',
    tabs: {
      title: 'Select service provider',
      finnairPnr: 'Finnair Booking Reference',
      aurinkomatkatBooking: 'Aurinkomatkat Booking Reference',
    },
    firstName: {
      label: 'First name *',
    },
    lastName: {
      label: 'Last name *',
    },
    finnairPnr: {
      label: 'Finnair Booking Reference *',
    },
    aurinkomatkatBooking: {
      label: 'Aurinkomatkat Booking Reference *',
    },
    callToAction: 'Search booking reference',
  },
  personalDataRequestForm: {
    title: 'Personal data request form',
    corporateId: 'Corporate ID:',
    address: 'Address:',
    regulations1:
      'According to article 15 of the EU General Data Protection Regulations, you have the right to access the personal data that Finnair...',
    regulations2:
      'If Finnair denies access to the data you requested, it must provide a written certificate of refusal explaining the reasons for denied access.',
  },
  loginToDownload: {
    title: 'Download your report',
    ingress: 'Here you can download the data report you have requested.',
    subTitle: 'Login and access your data report',
    login: 'Login',
    proceed: 'Proceed',
    boldText: 'Log in with your Finnair Plus account to access your data report',
    text: 'If you did not login to your Finnair Plus when you requested your data, you are not able to access your report online. Instead, you will receive your report via encrypted email when its ready',
  },
  downloadReport: {
    title: 'Download your report',
    ingress:
      'Here you can download the data report you have requested. Your data report is available here for 60 days after it has been published for download.',
    noData: 'No data requests',
    requests: 'Your requests',
    request: 'Request',
    downloadPdf: 'Download report',
    downloadJson: 'Download report in json -format',
    requestSubmitted: 'Request submitted',
    requestInProgress: 'Request in progress',
    requestReady: 'Data report ready',
    requestInactive: 'Data report inactive',
  },
  extendedDataRequestForm: {
    title: 'Access to and erasure of your personal data',
    iconsLabel: {
      checkList: 'Fill in and send your data request form',
      holdMyBooking: 'Your data is gathered/erased',
      onlineChecked: 'We will let you know when your data report is ready/your data has been erased',
    },
    paragraphText: 'Select the service provider below and fill in the data request form',
    loginButton: 'Login and access form',
    accessForm: 'Access form',
    acceessWithout: 'Access form without login',
    prefillInfo:
      'Log in to your Finnair Plus account to fill your information in the data request form. As soon as your request has been performed, you will be able to download your report online by logging in.',
    tipForMember: 'In the case you want your Finnair Plus account deleted, please login.',
    tipForNonMember:
      'In the case you are not a Finnair Plus member, please fill in your information in the link below.',
    tabs: {
      title: 'Select service provider',
      finnairPnr: 'Finnair Booking Reference',
      aurinkomatkatBooking: 'Aurinkomatkat Booking Reference',
    },
    finnairPnr: {
      label: 'Finnair Booking Reference *',
    },
    aurinkomatkatBooking: {
      label: 'Aurinkomatkat Booking Reference *',
    },
    callToAction: 'Download form',
  },
  downloadData: {
    title: 'Download your data',
    ingress: 'Lorem ipsum dolor sit amet.',
    subtitle: 'consectetur adipiscing elit',
    text: 'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
    caseId: {
      label: 'Case id',
    },
    callToAction: 'Search',
  },
  footer: {
    finnairPolicy:
      '<a href="https://www.finnair.com/en/info/finnair-privacy-policy" target="_blank" rel="noopener noreferrer">Finnair Privacy Policy</a>',
    aurinkomatkatPolicy:
      '<a href="https://www.aurinkomatkat.fi/yritysinfo/tietosuoja" target="_blank" rel="noopener noreferrer">Aurinkomatkat Privacy Policy</a>',
  },
  formValidationMessages: {
    required: 'Required',
    shouldBe9Numbers: 'Membership number must contain 9 digits',
    shouldNotContainNumbers: 'Must not contain digits',
    shouldNotContainChars: 'Must not contain letters',
    shouldBe6AlphaNum: 'The booking reference must contain six letters or digits',
    fiveTo100AplhaNum: 'Must contain 5–100 letters or digits',
    shouldBe7Numbers: 'Must contain 7 digits, starting with 5 or 6',
    invalidPeriod: 'Dates are not in a valid format',
    oneSelectionRequired: 'You need to select at least one',
  },
};

const fi_FI: typeof en = {
  welcome: 'Finnairin tietosuojalupaus',
  changeLanguage: 'Vaihda kieltä',
  ingress:
    'Tietosuoja on Finnairille ensisijaisen tärkeää. Sitoudumme suojaamaan asiakkaidemme henkilötiedot ja kunnioittamaan heidän yksityisyyttään.',
  home: 'Etusivu',
  login: {
    loggedIn: 'Olet näköjään kirjautunut tunnuksella {0}. Tervetuloa {1}!',
    callToAction: 'Kirjaudu sisään',
    logoutStatus: 'Kirjaudu ulos',
    logoutAction: 'Kirjaudu ulos',
  },
  languageMenuTitle: 'Kaikki kielet',
  selectedCountry: {
    finland: 'Suomi',
    international: 'Kansainvälinen',
  },
  heroImage: {
    heading: 'Paikanpitäjä',
    paragraph: 'Paikanpitäjä',
  },
  landingPage: {
    title: 'Henkilötietojen käsittelyssä noudatamme mm. seuraavia periaatteita:',
    principle1:
      'Olemme läpinäkyviä niiden tietojen suhteen, joita sinusta asiakkaanamme keräämme ja kerromme mihin tarkoitukseen tietoja käytämme.',
    principle2:
      'Kunnioitamme asiakkaidemme tietosuojaoikeuksia ja tarjoamme sähköiset työkalut omien henkilötietojen käsittelyä koskevien pyyntöjen tekemiseksi.',
    principle3:
      'Sinulla on mahdollisuus vaikuttaa henkilötietojesi käsittelyyn ja siihen millaista markkinointia vastaanotat.',
    principle4:
      'Käsittelemme henkilötietojasi vain etukäteen määriteltyihin, tietosuojaselosteessamme ilmoitettuihin käyttötarkoituksiin kuten toivomiesi palvelujen tarjoamiseen, sinulle sopivien tarjousten lähettämiseen sekä asiakaskokemuksesi parantamiseen.',
    principle5: 'Pidämme henkilötiedot ajan tasalla emmekä säilytä niitä pidempään kuin tarpeellista.',
    principle6:
      'Käsittelemme henkilötietoja EU:n tietosuoja-asetuksen sekä muun soveltuvan lainsäädännön mukaisesti ja suojaamme ne luvattomalta käsittelyltä.',
    privacyPolicyLink: 'Tietosuojaselosteestamme',
    privacyPolicyLinkUrl: 'https://www.finnair.com/fi-fi/info/finnairin-tietosuojaseloste',
    paragraph1:
      ' saat tarkemmat tiedot Finnairin henkilötietojen käsittelystä ja siitä, kenelle luovutamme näitä tietoja tarvittaessa.',
    paragraph2:
      'Alla voit muuttaa eväste- ja markkinointiasetuksiasi sekä tehdä tarkastuspyynnön saadaksesi tiedon sinua koskevasta asiakashistoriasta.',
    paragraph3:
      'Jos sinulla on kysyttävää tietosuojaselosteestamme, tavoitat Finnairin tietosuojavastaavan osoitteesta ',
  },
  navigationMenu: {
    cookieConsents: {
      title: 'Hallinnoi evästeasetuksiasi',
      description: 'Tarkista evästeasetuksesi ja päätä, miten seuraamme asiointiasi verkossa',
    },
    marketingConsents: {
      title: 'Hallinnoi markkinointiasetuksiasi',
      description: 'Valitse, millaista markkinointia haluat vastaanottaa meiltä ja yhteistyökumppaneiltamme',
    },
    exportData: {
      title: 'Tee tietojasi koskeva pyyntö',
      description:
        'Pyydä raportti koskien varauksiasi ja asiakashistoriaasi tai tietojen poistoa tai tietojen saamista koneluettavassa muodossa.',
    },
    download: {
      title: 'Lataa tietosi',
      description: 'Lataa tietosi',
    },
  },
  cookieConsentPage: {
    heroImage: {
      title: 'Evästeasetukset',
      ingress: 'Lue miksi käytämme erilaisia evästeitä ja miten parannamme niiden avulla käyttökokemustasi.',
    },
    cookiesTips: 'Voit muuttaa evästeasetuksiasi koska tahansa sivun alalaidasta löytyvästä linkistä.',
    beforeCheckboxesText:
      'Valitse, mitä evästeitä haluat meidän käyttävän. Voit muuttaa asetuksiasi milloin haluat. Lisää tietoa siitä, miten keräämme ja käytämme henkilökohtaisia tietoja, löydät Finnairin yksityisyydensuojaselosteesta.',
    title: 'Cookie-suostumukset',
    saveBtn: 'Salli valintani',
    allowAllBtn: 'Salli kaikki',
    changesSaved: 'Muutokset tallennettu',
    loadingText: 'Ladataan',
    finnairMayUse: 'Valitse evästeasetuksesi siirtämällä liukusäädintä',
    intro:
      '<p>Valitse alta, mitä evästeitä haluat meidän käyttävän. Voit muuttaa asetuksesi milloin haluat klikkaamalla “Muuta evästeasetuksia” -linkkiä jokaisen sivun alaosassa. Voit lukea lisää evästeistä ja siitä, mihin niitä käytämme <a' +
      ' href="https://www.finnair.com/fi-fi/info/finnairin-ev%C3%A4stek%C3%A4yt%C3%A4nteet" target="_blank" rel="noopener noreferrer">evästekäytänteistämme</a>.</p>',
  },
  marketingConsentPage: {
    heroImage: {
      title: 'Markkinointiasetukset',
      ingress:
        'Haluamme, että saat kaiken irti Finnair Plus -jäsenyydestäsi tai Finnair-profiilistasi ja pysyt ajan tasalla viimeisimmistä' +
        ' tarjouksista ja tapahtumista.',
    },
    title: 'Hallinnoi markkinointiasetuksiasi',
    beforeCheckboxesText: 'Valitse millaista markkinointia haluat vastaanottaa meiltä ja kumppaneiltamme.',
    login: 'Oletko Finnair Plus –jäsen? Kirjaudu sisään ja hallinnoi markkinointiasetuksiasi.',
    saveBtn: 'Tallenna',
    confirmSave: 'Tallentamalla muutokset vahvistan yllä olevat valinnat.',
    loadingText: 'Ladataan',
  },
  exportDataScreen: {
    title: 'Tee henkilötietojasi koskeva pyyntö',
    ingress:
      'Voit pyytää raportin tiedoista, joita olemme tallentaneet sinusta asiakasrekisteriimme tai pyytää tietojen poistamista.',
    fplusIngress: 'Käytäthän tätä itsepalvelutoimintoa tehdäksesi tietojen tarkastus- tai poistopyynnön.',
    amIngress:
      'Voit pyytää raportin tiedoista, joita olemme tallentaneet sinusta asiakasrekisteriimme tai pyytää tietojen poistamista.',
    downloadTitle: 'Kirjaudu ja tarkastele tietopyyntöraporttiasi',
    downloadIngress: 'Lataa valmis tietopyyntöraporttisi.',
    note: 'Huom',
    fplusTitle: 'Matkani ja asiointini Finnair Plus -jäsenenä',
    fplusField: 'Finnair Plus -jäsennumero *',
    fplusHint: 'Tiesithän, että voit tarkastella näitä tietoja heti, jos kirjaudut Finnair Plus -tilillesi?',
    otherTripsWithFinnair: 'Muut matkani Finnairilla',
    otherTripsWithAurinkomatkat: 'Matkani Aurinkomatkoilla',
    otherTripsFirstName: 'Etunimi (kuten varauksessa) *',
    otherTripsLastName: 'Sukunimi (kuten varauksessa) *',
    otherTripsBookingReferences: 'Varaustunnukset',
    otherTripsPnrsHint: 'Erottele varaustunnukset pilkulla, esim. Y2KP3D, YHK76W, IUY786',
    otherTripsAmBookingIdsHint: 'Erottele varaustunnukset pilkulla, esim. 6123456, 6234567, 6345678',
    otherTripsBookingReferencesHint:
      'Tiesithän, että näet tiedot tulevista varauksistasi suoraan Varaukseni-palvelusta?',
    otherTripsParagraph:
      'Jos et muista varaustunnusta, voit myös pyytää tietoja matkan kohteen ja ajankohdan perusteella.',
    otherTripsTravelDetails: 'Matkan tiedot (lähtö- ja kohdekaupunki sekä päivämäärä)',
    otherTripsTravelDetailsHint: 'Esimerkiksi: Helsinki-New York, 2018/3/30',
    otherTripsTravelDetailsAdd: 'Lisää uusi matkakohde',
    purchasesTitle: 'Ostokseni Finnair Shopissa, Nordic Skyssa ja lennon aikana',
    purchasesFplusId: 'Finnair Plus -jäsennumero (jos olet jäsen)',
    purchasesName: 'Nimi *',
    purchasesFlightNumberAndDate: 'Lennon numero ja päivämäärä',
    purchasesFlightNumberAndDateHint: 'Esimerkiksi: AY1723 2018/12/30',
    feedbackTitle: 'Käynnissä oleva tai aiempi asiakaspalaute / korvaushakemus',
    feedbackDetails: 'Tapausnumero *',
    feedbackDetailsHintFinnair: 'Erottele tapausnumerot pilkulla, esim. 12345678, 65432178',
    feedbackDetailsHintAurinkomatkat: 'Erottele tapausnumerot pilkulla, esim. 00012345, 00011111, 00055555',
    surveyTitle: 'Vastaukseni asiakaskyselyyn',
    surveyDetails: 'Vastausaika (vuosi ja kuukausi), sähköposti, josta vastasit sekä puhelinnumero *',
    surveyDetailsHint: 'Esimerkiksi: 2017/04, test@email.com, +358401234567',
    surveyAddRow: 'Lisää uusi asiakaskysely',
    additionalTitle: 'Lisäksi haluan tarkastella seuraavia tietoja',
    additionalDetails: 'Yksityiskohdat  *',
    additionalDetailsHint:
      'Anna kaikki saatavilla olevat lisätiedot, kuten puhelinnumero, josta soitit asiakaspalveluun, sähköpostisoite, josta otit meihin yhteyttä tai varaustunnus, jota käsiteltiin chat-palvelumme kautta.',
    additionalParagraphFinnairOne:
      'Tarkastellaksesi muita tietoja, jotka eivät sisälly edeltäviin kohtiin, kuten viestintään liittyviä tietoja, otathan yhteyttä privacy@finnair.com.',
    additionalParagraphAurinkomatkat:
      'Tarkastellaksesi muita tietoja, jotka eivät sisälly edeltäviin kohtiin, kuten viestintään liittyviä tietoja, otathan yhteyttä privacy@finnair.com.',
    additionalParagraph2: 'Haluan tarkastella edellä mainittuja tietoja seuraavalta ajanjaksolta:',
    additionalPeriod: 'Ajanjakso *',
    additionalPeriodHint: 'Esimerkiksi: 2017/01/31-2018/01/31',
    eraseTitleFinnair: 'Haluan, että henkilötietoni poistetaan Finnair Plus -asiakasrekisteristä',
    eraseTitleAurinkomatkat: 'Haluan, että henkilötietoni poistetaan Aurinkomatkojen asiakasrekisteristä',
    eraseTitleNotLoggedIn: 'Haluan, että henkilötietoni poistetaan',
    eraseParagraph:
      'Perustuen oikeuteesi tulla unohdetuksi, voit pyytää meitä poistamaan henkilökohtaiset tietosi järjestelmistämme. Huomioithan, että joissain tapauksissa kaikkia tietojasi ei voida poistaa, esimerkiksi jos ne liittyvät ostamasi palvelun toimittamiseen tai yhtiöllämme on laillinen peruste jatkaa niiden käsittelyä.',
    baseTitle: 'Yhteys- ja yksilöintitietosi',
    baseFirstName: 'Etunimi *',
    baseFamilyName: 'Sukunimi *',
    baseAddress: 'Osoite *',
    baseEmail: 'Sähköpostiosoite *',
    basePhoneNumber: 'Puhelinnumero *',
    baseOtherContactDetails: 'Muut yhteystiedot (esim. puhelinnumero toimistoaikana, työsähköposti)',
    baseCopyOfPassport: 'Kopio passista',
    submitButton: 'Lähetä pyyntö',
    forMoreInfo: 'Lisätietoja:',
    privacyPolicy: 'Tietosuojaseloste',
  },
  lightDataRequestForm: {
    title: 'Henkilökohtaiset tietosi',
    smallHeading: 'Saat sinusta tallennetuista tiedoista raportin, joka sisältää seuraavat asiat:\n',
    loggedInItems:
      'Finnair Plus -jäsentietosi\nFinnair Plus -tilitapahtumasi\nFinnair Plus -lentosi\nFinnair Plus -erityispalveluvaraukset\nFinnair Plus annetut suostumukset',
    itemForBooking: 'Matkustustiedot: {0}',
    disclaimer:
      'Raportti henkilökohtaisista tiedoistasi koostetaan järjestelmistämme automaattisesti. Tavallisesti raportti on saatavilla vuorokauden kuluessa tietopyynnön jättämisestä, mutta joissakin tapauksissa raportin koostaminen voi kestää 30 päivää.\n' +
      '\n' +
      'Kun raporttisi on valmis ladattavaksi, lähetämme sinulle ilmoituksen alla ilmoittamaasi sähköpostiosoitteeseen:\n',
    email: {
      label: 'Syötä sähköpostiosoitteesi *',
    },
    submit: 'Lähetä pyyntö',
    processing: 'Käsitellään',
  },
  exportDataSuccessful: {
    title: 'Pyyntösi on lähetetty',
    ingressLoggedIn: 'Saat ilmoituksen sähköpostitse, kun raporttisi on valmis ladattavaksi.',
    ingressWithoutLoggedIn: 'Kun raporttisi on valmis, lähetämme sen sinulle turvasähköpostilla.',
    callToAction: 'Sulje ja palaa alkuun',
  },
  exportDataFailure: {
    title: 'Jokin meni pieleen',
    ingress:
      'Valitettavasti tietopyynnön tekeminen ei nyt onnistunut. Kehittäjiä on ilmoitettu aiheesta. Ole hyvä ja kokeile myöhemmin uudelleen.',
    callToAction: 'Sulje ja palaa alkuun',
  },
  loginToViewData: {
    title: 'Tarkastele tietojasi Finnair Plus -tilin kautta',
    ingress:
      'Kirjautumalla Finnair Plus -tilillesi voit pyytää raportin, joka sisältää tietoja muun muassa menneistä ja tulevista varauksistasi ja tilitapahtumistasi.\n',
    moveToForm: 'Jätä tietopyyntö',
  },
  loginToMarketingConsent: {
    title: 'Hallinnoi markkinointiasetuksiasi',
    ingress:
      'Kirjaudu sisään Finnair Plus- tai Finnair-tilillesi, jotta voit tarkastella ja muuttaa markkinointiasetuksiasi.',
    loginTitle: 'Hallinnoi markkinointiviestiasetuksiasi',
    loginDescription:
      'Kirjaudu sisään Finnair Plus- tai Finnair-tilillesi, jotta voit tarkastella ja muuttaa markkinointiasetuksiasi.',
    managePreferences: 'Muuta asetuksia',
  },
  exportSingleBookingForm: {
    title: 'Hae yhden varauksen tiedot',
    ingress: 'Jos et ole Finnair Plus -jäsen, voit silti nähdä yhden varauksen tiedot.',
    tabs: {
      title: 'Valitse palveluntarjoaja',
      finnairPnr: 'Finnairin varaustunnus',
      aurinkomatkatBooking: 'Aurinkomatkojen varaustunnus',
    },
    firstName: {
      label: 'Etunimi * ',
    },
    lastName: {
      label: 'Sukunimi *',
    },
    finnairPnr: {
      label: 'Finnairin varaustunnus *',
    },
    aurinkomatkatBooking: {
      label: 'Aurinkomatkojen varaustunnus *',
    },
    callToAction: 'Hae varaustunnuksella',
  },
  extendedDataRequestForm: {
    title: 'Tietojesi tarkastus- ja poistopyyntö',
    iconsLabel: {
      checkList: 'Täytä ja lähetä tietopyyntölomake',
      holdMyBooking: 'Tietosi kerätään/poistetaan',
      onlineChecked: 'lmoitamme sinulle, kun raporttisi on valmis/tietosi on poistettu',
    },
    paragraphText: 'Valitse alla oleva palveluntarjoaja ja täytä tietopyyntölomake.',
    loginButton: 'Kirjaudu ja avaa lomake',
    accessForm: 'Avaa lomake',
    acceessWithout: 'Avaa lomake kirjautumatta',
    prefillInfo:
      'Kirjauduthan Finnair Plus -tilillesi täyttääksesi tietosi tietopyyntölomakkeelle. Kun tietopyyntösi on käsitelty, voit ladata tietopyyntöraportin kirjautumalla sisään.',
    tipForMember: 'Mikäli haluat poistaa Finnair Plus -tilisi, sinun tulee kirjautua sisään.',
    tipForNonMember: 'Mikäli et ole Finnair Plus -jäsen, sinun tulee täyttää tietosi alla olevan linkin kautta.',
    tabs: {
      title: 'Valitse palveluntarjoaja\n',
      finnairPnr: 'Finnair Booking Reference',
      aurinkomatkatBooking: 'Aurinkomatkat Booking Reference',
    },
    finnairPnr: {
      label: 'Finnair Booking Reference *',
    },
    aurinkomatkatBooking: {
      label: 'Aurinkomatkat Booking Reference *',
    },
    callToAction: 'Lataa lomake',
  },
  personalDataRequestForm: {
    title: 'Personal data request form',
    corporateId: 'Corporate ID:',
    address: 'Address:',
    regulations1:
      'According to article 15 of the EU General Data Protection Regulations, you have the right to access the personal data that Finnair...',
    regulations2:
      'If Finnair denies access to the data you requested, it must provide a written certificate of refusal explaining the reasons for denied access.',
  },
  loginToDownload: {
    title: 'Lataa tietopyyntö- raporttisi',
    ingress: 'Täältä voit ladata tietopyyntöraporttisi.',
    subTitle: 'Kirjaudu ja tarkastele tietopyyntöraporttiasi',
    login: 'Kirjaudu sisään',
    proceed: 'Jatka kirjautuneena',
    boldText: 'Kirjaudu sisään Finnair Plus -tilillesi tarkastellaksesi tietopyyntöraporttiasi',
    text: 'Jos et jättänyt tietopyyntöäsi kirjautuneena Finnair Plus -tilillesi, et pysty tarkastelemaan raporttiasi verkossa. Sen sijaan lähetämme raportin sinulle turvasähköpostilla sen valmistuttua.',
  },
  downloadReport: {
    title: 'Lataa tietopyyntö- raporttisi',
    ingress:
      'Täältä voit ladata pyytämäsi tietopyyntöraportin. Raportti on ladattavissa 60 päivän ajan sen muodostamisesta.',
    noData: 'Ei tietopyyntöraportteja',
    requests: 'Tietopyyntösi',
    request: 'Tietopyyntöraportti',
    downloadPdf: 'Lataa raportti',
    downloadJson: 'Lataa raportti json -muodossa',
    requestSubmitted: 'Tietopyyntö lähetetty',
    requestInProgress: 'Tietopyyntöä käsitellään',
    requestReady: 'Tietopyyntöraportti valmis',
    requestInactive: 'Tietopyyntöraportti poistettu',
  },
  downloadData: {
    title: 'Lataa tietosi',
    ingress: 'Lorem ipsum dolor sit amet',
    subtitle: 'consectetur adipiscing elit',
    text: 'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
    caseId: {
      label: 'Tapausnumero',
    },
    callToAction: 'Hae',
  },
  footer: {
    finnairPolicy:
      '<a href="https://www.finnair.com/fi-fi/info/finnairin-tietosuojaseloste" target="_blank" rel="noopener noreferrer">Finnair Privacy Policy</a>',
    aurinkomatkatPolicy:
      '<a href="https://www.aurinkomatkat.fi/yritysinfo/tietosuoja" target="_blank" rel="noopener noreferrer">Aurinkomatkat Privacy Policy</a>',
  },
  formValidationMessages: {
    required: 'Pakollinen kenttä',
    shouldBe9Numbers: 'Jäsennumeron tulee olla 9-numeroinen',
    shouldNotContainNumbers: 'Ei voi sisältää numeroita',
    shouldNotContainChars: 'Ei voi sisältää kirjaimia',
    shouldBe6AlphaNum: 'Varaustunnuksessa on oltava kuusi kirjainta tai numeroa',
    fiveTo100AplhaNum: 'Tunnuksessa on oltava 5-100 kirjainta tai numeroa',
    shouldBe7Numbers: 'Varaustunnuksen tulee olla 7-numeroinen ja alkaa luvuilla 5 tai 6',
    invalidPeriod: 'Päivämäärät eivät ole sallitussa muodossa',
    oneSelectionRequired: 'Valitse vähintään yksi',
  },
};

export const TRANSLATIONS: { [k in IncludedTranslations]: typeof en } = {
  en: en,
  fi_FI: fi_FI,
};
